import * as Cookies from "js-cookie";
import { hospitalFloaterEvent, hospitalSelected, hospitalSet } from "./gaEvents";
import { cookieSetter, setIpHospitalId, setNoIpHospitalId, setGeoHospital } from "./hospitalCookie";
import { globalDef } from "./siteDefinitions";
import { getUrlParam } from "./utility";

let suggestedHospital: any = null;

export function hospitalDetectorInit(hospitalDetector: HTMLElement) {
    // The hospital floater is available, so let's show the correct text on it.
    if (document.querySelector(".hospital-floater")) {
        setupHospPickerText();
    }

    const saveDetectedButton = document.querySelector(".js-save-detected");

    // Uses the ip cookie to set the hospital on button click
    saveDetectedButton?.addEventListener("click", saveDetected);

    // Closes the hospital detector
    document.querySelector(".hospital-detector__close")?.addEventListener("click", closeHospitalDetector);

    document.addEventListener("DOMContentLoaded", hospitalDetectorVisibility);

    const hospitalSearch = hospitalDetector.querySelector(".js-detector-search");

    // Listens to the custom element for the hospitalSelected event
    if (hospitalSearch) {
        hospitalSearch.addEventListener("hospitalSelected", ((event: CustomEvent) => {
            const { hospitalId } = event.detail;

            saveSelected(hospitalId);
        }) as EventListener);

        // Listens to the custom element for the geolocationFound event
        hospitalSearch.addEventListener("geolocationFound", ((event: CustomEvent) => setGeoHospital(event)) as EventListener);
    }
}

/**
 * Closes the hospital detector
 *
 * If it's the on the initial load the close button will set the cookie and redirect them
 */
function closeHospitalDetector() {
    const hospitalDetector = document.querySelector(".hospital-detector");

    if (document.querySelector(".page-template-redirect-to-hospital")) {
        const hospIdCookie = Cookies.get("hospital_id");
        const ipHospitalId = Cookies.get("ip_hospital_id");
        const noHospitalFound = Cookies.get("no_ip_hospital_id");

        if (noHospitalFound) {
            location.replace(`${globalDef.mainDomain}/home`);
            return; // Has to be a separate line
        } else if (ipHospitalId !== "/" && typeof hospIdCookie === "undefined") {
            saveDetected();
            return; // Has to be a separate line
        }
    }

    hospitalDetector?.removeAttribute("open");
}

/**
 * Save the detected hospital cookie.
 */
function saveDetected() {
    const ipHospitalId = suggestedHospital.hospitalId;
    hospitalSet();
    const selectButtons = document.querySelector(".detected-hospitals-buttons") as HTMLElement;

    cookieSetter(ipHospitalId as string);

    if (document.querySelector(".loading-circle__loader")) {
        (document.querySelector(".loading-circle__loader") as any).loading = true;
    }

    if (selectButtons) {
        selectButtons.style.display = "none";
    }

    location.replace(suggestedHospital.CmnhWebsite);
}

/**
 * Set up the hospital picking text for the modal.
 */
function setupHospPickerText() {
    const hospIdCookie = Cookies.get("hospital_id");
    const hospFloater = document.querySelector(".hospital-floater");
    const viewingHospID = globalDef.body.dataset.hospitalId as string;

    if (typeof hospIdCookie === "undefined" || hospIdCookie !== viewingHospID) {
        hospFloater!.textContent = "Make this my Hospital";
        hospFloater?.addEventListener("click", () => {
            hospitalFloaterEvent("set");
            cookieSetter(viewingHospID);
            setupHospPickerText();
        });
    } else if (hospIdCookie === viewingHospID) {
        hospFloater!.textContent = "Change Your Hospital";
        hospFloater?.addEventListener("click", () => {
            hospitalFloaterEvent("change");
            document.querySelector(".hospital-detector")?.setAttribute("open", "");
            (document.querySelector(".hospital-detector__spinner") as HTMLElement).style.display = "none";
            (document.querySelector(".hospital-detector-1") as HTMLElement).style.display = "none";
            (document.querySelector(".hospital-detector-2") as HTMLElement).style.display = "block";
            (document.querySelector(".js-detector-search") as any).focusSearch();
        });
    }
}

/**
 * Will set the hospital cookie and redirect them to their new hospital
 */
function saveSelected(hospitalId: string) {
    const parsedHospitalId = parseInt(hospitalId);
    const redirectHospitalId = marketChangeRedirect(parsedHospitalId);
    if (redirectHospitalId !== parsedHospitalId) {
        hospitalId = redirectHospitalId.toString();
    }
    hospitalSelected(Cookies.get("hospital_id") as string);
    cookieSetter(hospitalId);
    location.replace(globalDef.noSlash);
}

function marketChangeRedirect(hospitalId: number) {
    // Redirect to Roanoke Market hospital
    switch (hospitalId) {
        case 5169:
            return 5024;
    }

    return hospitalId;
}

/**
 * Display Select Hospital
 */
function displaySelectHospital() {
    const close = document.querySelector(".hospital-detector__close") as HTMLElement;
    const spinner = document.querySelector(".hospital-detector__spinner") as HTMLElement;
    const page1 = document.querySelector(".hospital-detector-1") as HTMLElement;
    const page2 = document.querySelector(".hospital-detector-2") as HTMLElement;
    spinner.style.display = "block";
    close.style.display = "none";
    page1.style.display = "none";
    page2.style.display = "none";

    let hospitalId = parseInt(Cookies.get("hospital_id") ?? '');
    if (hospitalId) {
        const beforeAjaxTimestamp = new Date();

        const redirectHospitalId = marketChangeRedirect(hospitalId);
        if (redirectHospitalId !== hospitalId) {
            cookieSetter(redirectHospitalId.toString());
            hospitalId = redirectHospitalId;
        }

        jQuery.ajax({
            url: `${globalDef.mainDomain + globalDef.ajaxFunctionsUrl}function=getHospital`,
            method: "GET",
            data: {
                id: hospitalId,
            },
            dataType: 'json',
            success(hospital) {
                const header = document.querySelector(".hospital-detector__header") as HTMLElement;
                header.textContent = header.textContent?.replace("We", "You") || '';
                (document.querySelector(".hospital-detector__detected-name") as HTMLElement).textContent = hospital.hospitalName;
                (document.querySelector(".detected-hospitals-buttons") as HTMLElement).style.display = "none";
                if (document.querySelector(".loading-circle__loader")) {
                    (document.querySelector(".loading-circle__loader") as any).loading = true;
                }

                spinner.style.display = "none";
                page1.style.display = "block";

                const afterAjaxTimestamp = new Date();
                const elapsedTime = afterAjaxTimestamp.getTime() - beforeAjaxTimestamp.getTime();
                const expectedWaitingTime = 5 * 1000;  // milliseconds
                const waitingTime = expectedWaitingTime - elapsedTime;
                setTimeout(() => {
                    location.replace(hospital.CmnhWebsite);
                }, waitingTime);
            },
        });
        return;
    }

    let hospitalIdByIP = parseInt(Cookies.get("ip_hospital_id") ?? '');
    if (hospitalIdByIP) {
        const redirectHospitalId = marketChangeRedirect(hospitalIdByIP);
        if (redirectHospitalId !== hospitalIdByIP) {
            setIpHospitalId(redirectHospitalId.toString());
            hospitalIdByIP = redirectHospitalId;
        }
        jQuery.ajax({
            url: `${globalDef.mainDomain + globalDef.ajaxFunctionsUrl}function=getHospital`,
            method: "GET",
            data: {
                id: hospitalIdByIP,
            },
            dataType: 'json',
            success(hospital) {
                suggestedHospital = hospital;

                // Hide loading
                spinner.style.display = "none";
                const hospitalName = document.querySelector(".hospital-detector__detected-name") as HTMLElement;
                hospitalName.textContent = suggestedHospital.hospitalName;

                // Show guessed hospital if it exists
                if (document.querySelector(".page-template-redirect-to-hospital")) {
                    page1.style.display = "block";
                }

                close.style.display = "block";
            },
        });
    } else {
        // Get it from ajax request
        jQuery.ajax({
            url: `${globalDef.mainDomain + globalDef.ajaxFunctionsUrl}function=nearestHospital`,
            method: "POST",
            data: {
                useip: 1
            },
            dataType: 'json',
            success(response) {
                const [ hospital ] = response;
                // Show guessed hospital if it exists
                if (hospital) {
                    const suggestedHospitalId = parseInt(hospital.hospitalId);
                    const redirectHospitalId = marketChangeRedirect(suggestedHospitalId);
                    if (redirectHospitalId !== suggestedHospitalId) {
                        setIpHospitalId(redirectHospitalId.toString());
                        location.replace(globalDef.noSlash);
                        return;
                    }

                    suggestedHospital = hospital;
                    setIpHospitalId(suggestedHospital.hospitalId);
                    const hospitalName = document.querySelector(".hospital-detector__detected-name") as HTMLElement;
                    hospitalName.textContent = suggestedHospital.hospitalName;
                    page1.style.display = "block";
                    page2.style.display = "none";
                } else {
                    setNoIpHospitalId();
                    page1.style.display = "none";
                    page2.style.display = "block";
                }

                // Hide loading
                spinner.style.display = "none";
                // Show close button
                close.style.display = "block";
            },
        });
    }
}

/**
 * Decides the visibility for both hospital detector pages.
 */
function hospitalDetectorVisibility() {
    const close = document.querySelector(".hospital-detector__close") as HTMLElement;
    const page1 = document.querySelector(".hospital-detector-1") as HTMLElement;
    const page2 = document.querySelector(".hospital-detector-2") as HTMLElement;
    const spinner = document.querySelector(".hospital-detector__spinner") as HTMLElement;
    const noHospitalFound = Cookies.get("no_ip_hospital_id");
    const hospitalDetector = document.querySelector(".hospital-detector") as any;

    /**
     * Show guessed hospital if it exists
     */
    if (document.querySelector(".page-template-redirect-to-hospital")) {
        displaySelectHospital();
    }

    /**
     * Attach click handler to the change hospital cta
     */
    const selectHosp = document.getElementById("selectHosp");
    if (selectHosp) {
        selectHosp.addEventListener("click", () => {
            spinner.style.display = "none";
            page1.style.display = "none";
            page2.style.display = "block";
            (document.querySelector(".js-detector-search") as any).focusSearch();
        });
    }

    /**
     * No hospital is found or the user is trying to access the search via the hospital_search query string param
     */
    const hospitalSearch = getUrlParam("hospital_search");
    if (noHospitalFound || hospitalSearch) {
        hospitalDetector.setAttribute("open", "true");

        spinner.style.display = "none";
        page1.style.display = "none";
        close.style.display = "block";
        page2.style.display = "block";

        (document.querySelector(".js-detector-search") as any).focusSearch();
    }
}
