import * as Cookies from "js-cookie";
import { globalDef } from "./siteDefinitions";
import { stripScripts } from "./utility";

/**
 * Set the hospital cookie.
 */
export function cookieSetter(hospitalId: string) {
    hospitalId = stripScripts(hospitalId);

    Cookies.remove("no_ip_hospital_id", {
        path: "/",
        domain: globalDef.cmnDomain,
        secure: true,
    });

    Cookies.set("hospital_id", hospitalId, {
        expires: 365,
        path: "/",
        domain: globalDef.cmnDomain,
        secure: true,
        sameSite: "Strict",
    });
}

export function setIpHospitalId(hospitalId: string) {
    Cookies.remove("no_ip_hospital_id", {
        path: "/",
        domain: globalDef.cmnDomain,
        secure: true,
    });

    Cookies.set("ip_hospital_id", hospitalId, {
        // Comment to keep it for how long session is kept
        // expires: new Date(new Date().getTime() + 15 * 60 * 1000), // 15 minutes
        path: "/",
        domain: globalDef.cmnDomain,
        secure: true,
        sameSite: "Strict",
    });
}

export function setNoIpHospitalId() {
    Cookies.set("no_ip_hospital_id", "1", {
        // Comment to keep it for how long session is kept
        expires: new Date(new Date().getTime() + 15 * 60 * 1000),   // 15 mins
        path: "/",
        domain: globalDef.cmnDomain,
        secure: true,
        sameSite: "Strict",
    });
}

/**
 * Actually sets the cookie.
 */
export function setGeoHospital(event: CustomEvent) {
    const [ detail ] = event.detail;
    if (!detail) {
        return;
    }
    const { hospitalId, hospitalName } = detail;

    Cookies.set("geo_hospital_id", hospitalId, {
        expires: 365,
        path: "/",
        domain: globalDef.cmnDomain,
        secure: true,
    });

    Cookies.set("geo_hospital_name", hospitalName, {
        expires: 365,
        path: "/",
        domain: globalDef.cmnDomain,
        secure: true,
    });
}
