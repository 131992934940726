// SCSS
import "../scss/custom.scss";

import { applyPolyfills, defineCustomElements } from "@cmnhospitals/components/loader";
import * as Cookies from "js-cookie";
import { login } from "./modules/account";
import { champions } from "./modules/champions";
import { showVideo } from "./modules/giveWorx";
import { hospitalDetectorInit } from "./modules/hospitalDetector";
import { LazyLoad } from "./modules/LazyLoad";
import { initMap } from "./modules/map";
import { globalDef } from "./modules/siteDefinitions";
import { blogPopup, getUrlParam, mobileWidth } from "./modules/utility";

// Lazy load some of the sections
async function loadMap() {
    if (document.getElementById("map")) {
        const options = {
            root: null,
            rootMargin: "350px",
            threshold: 0.1,
        };

        if (!("IntersectionObserver" in window)) {
            await import(/* webpackChunkName: "intersection-observer" */ "intersection-observer");
        }

        const sectionWatcher = new IntersectionObserver((entries, observer) => {
            entries.forEach(async (entry) => {
                if (entry.isIntersecting) {
                    await initMap();
                    const loader = document.querySelector(".map-loader") as any;

                    if (loader) {
                        loader.loading = false;
                    }
                    observer.disconnect();
                }
            });
        }, options);

        sectionWatcher.observe(document.getElementById("map") as HTMLElement);
    }
}

loadMap();

/**
 * Load polyfills if necessary and then sets up custom components.
 * The components will only be downloaded if they are used on the page.
 * This is handled by the Stencil loader.
 */
applyPolyfills().then(() => {
    defineCustomElements(window);
});

const hospitalDetector = document.querySelector(".hospital-detector") as HTMLElement | null;
if (hospitalDetector) {
    hospitalDetectorInit(hospitalDetector);
}

if (document.getElementById("donation_detected_hospital_id")) {
    (document.getElementById("donation_detected_hospital_id") as HTMLInputElement).value = Cookies.get("ip_hospital_id") as string;
}

const loginBtn = document.querySelector(".account-login");

loginBtn?.addEventListener("click", login);

function backToTop(scrollButton: HTMLElement) {
    const scrollTrigger = 100; // px
    const scrollTop = window.scrollY;
    if (scrollTop > scrollTrigger) {
        scrollButton.classList.add("show");
    } else {
        scrollButton.classList.remove("show");
    }
}

if (document.querySelector(".champion-slider")) {
    const champModalTriggers = document.querySelectorAll<HTMLElement>(".champion-modal-trigger");
    for (const champTrigger of champModalTriggers) {
        champTrigger.addEventListener("click", () => champions(champTrigger));
    }
}

const newsletterForm = document.querySelector<HTMLFormElement>(".newsletter-form");

if (newsletterForm) {
    (window as any).loadNewsletterRecaptcha = () => {
        // Adding recaptcha
        (window as any).newsletterWidget = grecaptcha.render("g-recaptcha-newsletter", {
            sitekey: globalDef.recaptchaSiteKey,
            callback: submitNewsletterForm,
            size: "invisible",
        });
    };

    const newsletterLoading = newsletterForm.querySelector<HTMLElement>(".newsletter-wrap__loading");
    const newsletterFieldset = newsletterForm.querySelector<HTMLFieldSetElement>("fieldset");
    const newsletterEmail = newsletterForm.querySelector<HTMLInputElement>("input[type=email]");

    newsletterEmail?.addEventListener("blur", () => {
        if (!newsletterEmail?.checkValidity()) {
            return newsletterForm.classList.add("error");
        }

        newsletterForm.classList.remove("error");
    });

    newsletterForm.addEventListener("submit", (e) => {
        e.preventDefault();

        if (!globalDef.cmnDebug) {
            grecaptcha.execute();
        } else {
            submitNewsletterForm();
        }
    });

    var submitNewsletterForm = async () => {
        const newsletterFormData = new FormData(newsletterForm);

        if (!newsletterEmail?.checkValidity()) {
            newsletterForm.classList.add("error");
            return;
        }

        if (!newsletterFieldset) {
            return;
        }

        if (!globalDef.cmnDebug) {
            newsletterFormData.append("g_recaptcha_response", grecaptcha.getResponse((window as any).newsletterWidget));
        }

        newsletterFieldset.disabled = true;

        (newsletterLoading as any).loading = true;

        const submit = await subscribeToNewsletterForm(newsletterFormData).catch(() => {
            newsletterForm.classList.add("error");
            newsletterFieldset.disabled = false;
            (newsletterLoading as any).loading = false;
            return;
        });

        if (submit === "success") {
            newsletterForm.style.display = "none";
            newsletterForm.classList.remove("loading");
            newsletterForm.classList.remove("error");
            document.querySelector<HTMLElement>(".newsletter-complete")!.style.display = "block";
        } else {
            newsletterForm.classList.add("error");
            newsletterFieldset.disabled = false;

            (newsletterLoading as any).loading = false;
        }
    };
}

function subscribeToNewsletterForm(newsletterValues: FormData) {
    return new Promise<string>((resolve, reject) => {
        $.ajax({
            url: `${globalDef.ajaxFunctionsUrl}function=submitNewsletter`,
            cache: false,
            type: "post",
            processData: false,
            contentType: false,
            data: newsletterValues,
            success(response) {
                try {
                    const jsonResp = JSON.parse(response);
                    resolve(jsonResp);
                } catch (e) {
                    reject(e);
                }
            },
            error(error) {
                reject(error);
            },
        });
    });
}


$(function () {
    const lazyImages = document.querySelectorAll<HTMLImageElement>("img.js-lazy");
    const lazyDivs = document.querySelectorAll<HTMLDivElement>("div.js-lazy");

    if (lazyImages.length > 0) {
        new LazyLoad(lazyImages, "image");
    }

    if (lazyDivs.length > 0) {
        new LazyLoad(lazyDivs, "div");
    }

    /**
     * Set ecode cookie if query param exists. This should run on all pages
     * @page all
     */
    const ecodeParam = getUrlParam("ecode") as string;

    if (ecodeParam) {
        Cookies.set("ecode", ecodeParam, {
            path: "/",
            domain: globalDef.cmnDomain,
            secure: true,
        });
    }

    /**
     * Search Customizations:
     * 1. Change the placeholder on the search form if we hit an error page
     */

    if (document.querySelector(".error404")) {
        (document.getElementById("s") as HTMLInputElement).placeholder = "Try searching...";
    }

    if (mobileWidth.matches) {
        /**
         * Mobile back to top button
         */
        const toTop = document.querySelector("#back-to-top") as HTMLElement;
        if (toTop) {
            backToTop(toTop);
            let ticking = false;
            window.addEventListener("scroll", () => {
                if (!ticking) {
                    window.requestAnimationFrame(() => {
                        backToTop(toTop);
                        ticking = false;
                    });

                    ticking = true;
                }
            });
            $("#back-to-top").on("click", () => {
                $("html,body").animate(
                    {
                        scrollTop: 0,
                    },
                    500
                );
            });
        }
    }

    /**
     * Setup the mobile hospital picker on the hospital page
     * @page hospital-page.php
     */
    if (document.querySelector(".hospital-floater") && mobileWidth.matches) {
        const scrollTrigger = (document.querySelector(".hospital-section") as HTMLElement).offsetHeight - (document.querySelector(".hospital-floater") as HTMLElement).offsetHeight;
        const showHospFloater = () => {
            const scrollTop = window.scrollY;
            if (scrollTop > scrollTrigger) {
                $(".hospital-floater").fadeOut();
            } else {
                $(".hospital-floater").fadeIn();
            }
        };
        showHospFloater();
        window.addEventListener("scroll", showHospFloater);
    }
});

const faqs = document.querySelectorAll(".faq");
if (faqs.length > 0) {
    for (const faq of faqs) {
        faq.addEventListener("toggle", () => {
            if (faq.getAttribute("aria-expanded") === "false") {
                faq.setAttribute("aria-expanded", "true");
            } else {
                faq.setAttribute("aria-expanded", "false");
            }
        });
    }
}

/**
 * What it do?
 */
const secret = "38384040373937396665";
const pressed: number[] = [];
window.addEventListener("keyup", (e) => {
    pressed.push(e.which);
    pressed.splice(-secret.length - 1, pressed.length - 10);
    if (pressed.join("").indexOf(secret) !== -1) {
        changeImage();
    }
});

function changeImage() {
    const oldSrc = "https://resources.childrensmiraclenetworkhospitals.org/cdn/images/CorporateWebsite/ThemeImages/cmnh-logo.svg";
    const newSrc = "https://resources.childrensmiraclenetworkhospitals.org/cdn/images/CorporateWebsite/ThemeImages/the-cage.png";
    const theCage = document.createElement("div");

    theCage.innerHTML = "<div class='the-cage'></div><div class='bigCage'></div>";
    globalDef.body.appendChild(theCage);
    document.querySelector(`img[src="${oldSrc}"]`)!.setAttribute("src", newSrc);
    if (globalDef.gaIncluded) {
        ga("send", "event", "Balloon Change", "Enabled");
    }
}

/**
 * Social Sharing click handlers
 */
const shares = document.querySelectorAll<HTMLElement>(".js-share");
if (shares.length > 0) {
    for (const share of shares) {
        share.addEventListener("click", () => blogPopup(share));
    }
}

// Set up local champions
const championLink = document.querySelector(".local-champion-link") as HTMLElement;
if (championLink) {
    championLink.addEventListener("click", () => {
        const abbr = championLink.dataset.abbr as string;
        document.getElementById(`${abbr}`)!.scrollIntoView({ behavior: "smooth" });
    });
}

/**
 * Log in if enter key pressed
 */
if (document.getElementById("cmnh_campaign_form")) {
    document.getElementById("cmnh_campaign_form")!.addEventListener("keyup", (e) => {
        if (e.keyCode === 13) {
            login();
        }
    });
}

const loadVideos = document.querySelectorAll(".open-video");
if (loadVideos.length > 0) {
    for (const video of loadVideos) {
        video.addEventListener("click", () => showVideo(video as HTMLElement));
    }
}

/**
 * Adds service worker if supported. This will be added back in: never
 */
// if ("serviceWorker" in navigator && !globalDef.cmnDebug) {
//     window.addEventListener("load", () => {
//         navigator.serviceWorker.register("/sw.js");
//     });
// }

/**
 * Having issues with the service worker. This will force unregister it for all users
 */
console.log("Check and remove service worker");

if ("serviceWorker" in navigator) {
    navigator.serviceWorker.getRegistration("/sw.js").then((registration) => {
        if (registration) {
            registration.unregister().then((boolean) => {
                // if boolean = true, unregister is successful
                console.log(`Unregister sw: ${boolean}`);
            });
        }
    });
}
